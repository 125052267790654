@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200;300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import '../src/scss/themes/theme-dark.scss';
@import '../src/scss/themes/theme-light.scss'; */

html {
	/* font-size: 62.5%; */
	-webkit-font-smoothing: antialiased;
	margin: 0;
	padding: 0;
}

.aligner {
	display: flex;
	align-items: center;
	justify-content: center;
}

.title-container {
	height: 50px;
}

.title-styles {
	font-family: 'Raleway', sans-serif;
	font-size: 200%;
}

.card-header {
	text-align: center!important;
	/* background-color: #eedaa9; */
}

.header-icon {
	height: 150px;
	width: 150px;
	color: #343a40;
}

header {
	position: relative;
	height: 520px;
	min-height: 450px;
	width: 100%;
	background-size: cover;
	-webkit-background-size: cover;
	text-align: center;
	overflow: hidden;
	background-color: #efe1bd;
	background-size: cover;
	padding-top: 150px;
	padding-bottom: 0;
  	font-size: 62.5%;
  	color: #343a40;
}

@media screen and (max-width: 475px) {
	.header-polaroid img {
		height: 180px;
	}
}
.language-icon {
	font-size: 50px;
	cursor: pointer;
}

.project-date {
	font-size: 16px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	padding: 8px 15px 2px 15px;
	position: relative;
	z-index: -500;
	border-top: 5px solid #696969;
	border-radius: 0 0 5px 5px;
	background-color: #696969;
	color: white;
}

.skills-tile {
	background-color: rgba(52, 58, 64, 0.3);
	padding: 10px 10px 5px 10px;
	width: 100px;
	margin: 5px 0 5px 0;
	border-radius: 8px;
}

.language {
	font-size: 25px;
	background-color: #e9d5a1;
	padding-bottom: 10px;
	padding-top: 80px;
}

header h1 {
	font-size: 400%;
	text-align: center;
	font-weight: 600 !important;
	color: #343a40;
	font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
	margin: 0 auto 18px auto;
	width: 100%;
}

.slider-image {
	border: 10px solid #D7CAAA;
}

img {
	position: relative;
    object-fit: contain;
}

.slider-iconfiy {
	margin-top: 10px;
}

.styles_typicalWrapper__1_Uvh::after {
	cursor: none !important;
	display: none;
}

#about {
	/* background: #efe1bd; */
	overflow: hidden;
  	font-size: 62.5%;
	padding-bottom: 100px;
	margin-top: 7%;
}

#about h1 {
	/* padding-top: 5%; */
	font: 18px/24px 'opensans-bold', sans-serif;
	text-transform: uppercase;
	letter-spacing: 3px;
	color: black;
	text-align: center;
}

#experience {
	background: #fff;
	overflow: hidden;
  	font-size: 62.5%;
	padding-bottom: 70px;
	margin-top: 7%;
}

#experience h1 {
	/* padding-top: 5%; */
	font: 18px/24px 'opensans-bold', sans-serif;
	text-transform: uppercase;
	letter-spacing: 3px;
	color: black;
	text-align: center;
}

#experience a {
	font: 18px/24px 'opensans-bold', sans-serif;
	text-transform: uppercase;
	letter-spacing: 3px;
	color: black;
	text-align: center;
}

.experience-polaroid{
	text-align: center!important;
	cursor: pointer;
}

.language-tag {
	background: #70abaf!important;
	margin: 5px;
	border-radius: 10px;
	display: inline-block;
	padding: 5px 15px 5px;
	text-align: center;
	text-decoration: none;
	box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	transition: all .20s linear;
	z-index: 0;
	position: relative;
}

.art-label {
	background: #efe1bd!important;
	margin: 5px;
	border-radius: 20px;
	display: inline-block;
	padding: 5px 15px 5px;
	text-align: center;
	text-decoration: none;
	box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	transition: all .20s linear;
	z-index: 0;
	position: relative;
}

art-set img {
	margin: 5px;
}

@media screen and (max-width: 1090px) {
	.experience-polaroid{
		margin: 15px 40px 15px !important;
	}
}

@media screen and (max-width: 888px) {
	.experience-polaroid{
		margin: 15px 15px 15px !important;
	}
}


#art {
	background: #fff;
	overflow: hidden;
  	font-size: 62.5%;
	padding-bottom: 70px;
	margin-top: 7%;
}

#art h1 {
	font: 18px/24px 'opensans-bold', sans-serif;
	letter-spacing: 3px;
	color: black;
	text-align: center;
}

.foto img {
	display: block;
	width: 100%;
}

.foto div {
	background: #ffffff;
	display: inline-block;
	margin: 0 auto 5% auto;
	padding: 10px 10px 5px;
	text-align: center;
	text-decoration: none;
	box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	transition: all .20s linear;
	z-index: 0;
	position: relative;
}

.foto div:after {
	color: #333;
	font-size: 25px;
	content: attr(title);
	position: relative;
	top: 15px;
}

.foto div:hover {
	-webkit-transform: scale(1.01);
	transform: scale(1.01);
	z-index: 10;
	box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
}

.project-title-settings {
	margin-top: 5%;
	font: 18px/24px 'opensans-bold', sans-serif;
	text-transform: uppercase;
	letter-spacing: 3px;
	color: black;
	text-align: center;
}

.polaroid img {
	display: block;
	max-width: 300px;
}

.polaroid span {
	background: #ffffff;
	display: inline-block;
	margin: 30px 75px 30px;
	padding: 15px 15px 15px;
	text-align: center;
	text-decoration: none;
	box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	transition: all .20s linear;
	z-index: 0;
	position: relative;
}

.project {
	background: #ffffff;
	margin: 20px 10px 10px;
	padding: 15px 15px 15px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	transition: all .20s linear;
}

@media screen and (max-width: 400px) {
	.project {
		background: none;
		margin: 10px 5px 5px;
		padding: 5px 5px 5px;
		border-bottom: 1px solid black;
		box-shadow: none;
	}
	.main-projects {
		width: 100%!important;
	}
}

.link-href {
	color: black;
}

.wave {
	font-size: 160%;
}

.font-trebuchet {
	font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

#preview {
	width: 500px;
	background-color: #ebeaf5;
	padding: 15px;
	position: relative;
	margin-bottom: 15%;
}

.center {
	display: flex;
	justify-content: center;
	align-items: center;
}

#resume {
	background: #efe1bd;
}

.experience-icon {
	font-size: 300%;
	margin-top: 25%;
	text-align: center;
}

.main-badge {
	font-size: 13px !important;
	text-align: left !important;
	padding: 5px 8px 5px 8px !important;
	vertical-align: baseline;
	background-color: #AE944F !important;
	color: white;
	font-weight: lighter !important;
	font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.experience-badge {
	font-size: 11px !important;
	text-align: left !important;
	padding: 5px 8px 5px 8px !important;
	vertical-align: baseline;
	background-color: #f9f5e9 !important;
	color: black;
	font-weight: lighter !important;
	font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.vertical-timeline-element-date {
	padding: 0 !important;
	margin: 0 !important;
}

@media only screen and (max-width: 1170px) {
	.experience-icon {
		font-size: 170%;
		margin-top: 27%;
		text-align: center;
	}
}

.modal-inside .modal-content {
	background: white;
}

.bars {
	width: 95%;
	float: left;
	padding: 0;
	text-align: left;
}

.bars .skills {
	margin-top: 36px;
	list-style: none;
}

.bars li {
	position: relative;
	margin-bottom: 60px;
	background: #ccc;
	height: 42px;
	border-radius: 3px;
}

.bars li em {
	font: 15px 'opensans-bold', sans-serif;
	color: #313131;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-weight: normal;
	position: relative;
	top: -36px;
}

.bar-expand {
	position: absolute;
	left: 0;
	top: 0;
	margin: 0;
	padding-right: 24px;
	background: #313131;
	display: inline-block;
	height: 42px;
	line-height: 42px;
	border-radius: 3px 0 0 3px;
}

.modal-close {
	text-align: right;
	padding: 10px 15px 10px 15px;
	cursor: pointer;
}

.close-icon {
	color: black;
	font-weight: lighter !important;
}

.modal-description {
	text-align: justify;
	padding: 5px 5px 0 5px;
	margin-bottom: 20px;
	font-size: 12px;
}

.awssld__next {
	outline: none !important;
}

.awssld__prev {
	outline: none !important;
}

.loader-bar-color {
	color: black !important;
}

#portfolio {
	background: #fff;
	overflow: hidden;
  	font-size: 62.5%;
	padding-bottom: 100px;
	margin-top: 7%;
}

#portfolio h1 {
	/* padding-top: 5%; */
	font: 18px/24px 'opensans-bold', sans-serif;
	text-transform: uppercase;
	letter-spacing: 3px;
	color: black;
	text-align: center;
}

.portfolio-item {
	max-width: 100%;
	margin-bottom: 15px;
	text-align: center;
}

.portfolio .portfolio-item .portfolio-item-caption {
	transition: all ease 0.5s;
	opacity: 0;
	background-color: rgba(51, 51, 51, 0.9);
}

.portfolio .portfolio-item .portfolio-item-caption:hover {
	opacity: 1;
}

.portfolio .portfolio-item .portfolio-item-caption .portfolio-item-caption-content {
	font-size: 1.5rem;
}

@media (min-width: 576px) {
	.portfolio .closeButtonResponsive {
		display: block;
	}
	.portfolio .portfolio-item {
		margin-bottom: 30px;
	}
}

#skills {
	background: #70abaf;
	min-height: 200px;
	width: 100%;
	overflow: hidden;
	/* padding-bottom: 10%; */
}

.section-title {
	padding-top: 2%;
	/* padding-bottom: 5%; */
	font: 18px/24px 'opensans-bold', sans-serif;
	text-transform: uppercase;
	letter-spacing: 3px;
	color: #343a40;
	text-align: center;
}

.skill-icon {
	font-size: 180%;
	color: white;
	text-align: center;
	position: relative;
}

footer {
	background: #343a40;
	/* background: #e6c265; */
	min-height: 70px;
	width: 100%;
	overflow: hidden;
	font-size: 14px;
	color: white;
	position: fixed;
	bottom: 0;
	text-align: center;
	z-index: 99 !important;
}

footer a,
footer a:visited {
	color: #fff;
}

footer a:hover,
footer a:focus {
	color: #fff;
}

.social-links {
	margin-top: 10px;
	font-size: 22px;
}

@media screen and (max-width: 640px) {
	.biggallery {
		display: none !important;
	}
}
@media screen and (min-width: 640px) {
	.smallgallery {
		display: none !important;
	}
}

.pswp__bg {
    background-color: #70abaf !important;
}

.Pswp_bg image-zoom-background {
    background-color: #70abaf !important;
}

